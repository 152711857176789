<template>
  <v-app>
    <p>Hola</p>
  </v-app>
</template>

<script>


export default {
  name: "CaptureQR",
}
</script>
